/* eslint-disable camelcase */
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ASSOCIATE_ASSIGNED_SUCCESS_TEXT } from 'containers/Home/constants';
import {
  setBatchJobs,
  setSelectedEditField,
} from 'state/ducks/staffingWorkPage/actions';
import { enqueueSnackbar } from 'notistack';
import LoadingIndicator from './LoadingIndicator';
import SearchAssociate from './SearchAssociate';
import Jyver from './Jyver';
import { putAssociate, removeAssociate } from '../../../services/associates';
import { formatErrorFromSubsystem } from '../../../lib/utils';
import { determineDisabledText, determineShowAssign } from '../utils';
import EditPencilOverlay from './EditPencilOverlay';
import { VARIANTS } from '../../../constants';
import SuggestAssociateButton from '../../SuggestAssociates/components/SuggestAssociateButton';

function Associate({ job, getUpdatedJob }) {
  const flagName = 'showSingleModeInlineEdit';
  const featureFlags = useSelector((state) => state.featureFlags);
  const isCanceled = job.status_central === 'Canceled';
  const isCompleted = job.status_central === 'Completed';
  const [isHovering, setIsHovering] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = job;
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );

  const isDisabled = isCanceled || isCompleted;
  const isSingleMode = Array.from(centralMode.values())[0] === 'single';

  const isSingleEditModeEnabled = featureFlags[flagName];

  const isEditDrawerEnabled =
    isSingleEditModeEnabled &&
    isSingleMode &&
    job.advantage_source_system_name === 'JET' &&
    !isDisabled;

  const showAssign = determineShowAssign(
    isHovering,
    isActive,
    isLoading,
    !isCanceled,
    !isCompleted,
    isSingleMode
  );
  const disabled =
    job.status_central === 'Completed' || job.status_central === 'Canceled';
  const searchInitialValue = user ? user.full_name : null;
  const jobId = job.id;

  const handleAssociateClick = (associateId, errorCallback) => {
    setIsLoading(true);
    putAssociate({ associateId, jobId })
      .then(() => {
        enqueueSnackbar(ASSOCIATE_ASSIGNED_SUCCESS_TEXT, {
          variant: VARIANTS.success,
        });
        setIsLoading(false);
        setIsActive(false);
        getUpdatedJob(jobId);
      })
      .catch((error) => {
        enqueueSnackbar(
          formatErrorFromSubsystem(
            'Error when assigning or un-assigning',
            error.response.data.detail,
            job.external_identifier,
            job.advantage_source_system_name
          ),
          { variant: VARIANTS.error }
        );
        setIsLoading(false);
        setIsActive(true);
        errorCallback();
      });
  };

  const handleOpenBatchEdit = () => {
    if (isEditDrawerEnabled) {
      dispatch(setSelectedEditField('associate'));
      dispatch(setBatchJobs([job]));
    }
  };

  return (
    <Tooltip
      title={determineDisabledText({
        isCanceled,
        isCompletedAssignment: isCompleted,
      })}
      arrow
    >
      <Box
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        onBlur={() => {
          setIsActive(false);
        }}
        onClick={isEditDrawerEnabled ? handleOpenBatchEdit : undefined}
        className="job-associate-column"
        css={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {user && !showAssign ? (
          <Jyver
            jobId={job.id}
            avatarUrl={user.photo_thumb_url || ''}
            fullName={user.full_name}
            phone={user.phone_number}
            jobsCompleted={user.jobs_completed}
            title={user.title}
            divisionName={user.division_name}
            employeeId={user.external_id}
          />
        ) : (
          <Box
            css={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
            className="search-associates"
          >
            {showAssign &&
              (isEditDrawerEnabled ? (
                <EditPencilOverlay id="associate-edit" />
              ) : (
                <>
                  <Box
                    className="search-associates-wrapper"
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '8px',
                    }}
                  >
                    <SearchAssociate
                      sx={{
                        width: '100%',
                      }}
                      value={searchInitialValue}
                      isActive={(newIsActive) => {
                        setIsActive(newIsActive);
                      }}
                      disabled={disabled}
                      onChange={({ id }, errorCallback) =>
                        handleAssociateClick(id, errorCallback)
                      }
                    />
                    {isLoading && (
                      <Box
                        css={{
                          margin: '7px 9.75px',
                        }}
                      >
                        <LoadingIndicator />
                      </Box>
                    )}
                    {user && !isLoading && (
                      <IconButton
                        className="associate-unassign-button"
                        onClick={() => {
                          setIsLoading(true);
                          removeAssociate(job.id)
                            .then(() => {
                              enqueueSnackbar(
                                'Associate was removed successfully!',
                                { variant: VARIANTS.success }
                              );
                              setIsLoading(false);
                              getUpdatedJob(job.id);
                            })
                            .catch((error) => {
                              enqueueSnackbar(
                                formatErrorFromSubsystem(
                                  'Error when assigning or un-assigning',
                                  error.response.data.detail,
                                  job.external_identifier,
                                  job.advantage_source_system_name
                                ),
                                { variant: VARIANTS.error }
                              );
                              setIsLoading(false);
                            });
                        }}
                        disabled={disabled}
                        onChange={({ id }, errorCallback) =>
                          handleAssociateClick(id, errorCallback)
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                  <Divider />
                  <SuggestAssociateButton job={job} disabled={disabled} />
                </>
              ))}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}

export default Associate;
