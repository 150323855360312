import React from 'react';
import { Box } from '@mui/system';
import { Button, Divider, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { removeNonNumeric } from '../../../services/text';

function DurationEdit({
  durationEdit,
  setDurationEdit,
  isLoading,
  durationView,
  selectedDurationHours,
  setSelectedDurationHours,
  selectedDurationMinutes,
  setSelectedDurationMinutes,
  isErrored,
}) {
  if (durationEdit) {
    return (
      <>
        <Typography
          variant="body2"
          css={{ paddingTop: '16px', paddingBottom: '12px' }}
        >
          Duration
        </Typography>
        <Box
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <Box
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextField
              autoFocus
              id="batch_edit_duration_hours_picker"
              type="text"
              label="Hours"
              disabled={isLoading}
              variant="outlined"
              value={selectedDurationHours}
              onChange={(event) => {
                setSelectedDurationHours(removeNonNumeric(event.target.value));
              }}
              onFocus={(event) => {
                event.target.select();
              }}
            />
            <Divider
              orientation="horizontal"
              css={{
                width: '16px',
                marginLeft: '8px',
                marginRight: '8px',
              }}
            />
          </Box>
          <TextField
            id="batch_edit_duration_minutes_picker"
            type="text"
            label="Minutes"
            variant="outlined"
            disabled={isLoading}
            value={selectedDurationMinutes}
            onChange={(event) => {
              setSelectedDurationMinutes(removeNonNumeric(event.target.value));
            }}
            error={isErrored}
            helperText={isErrored ? 'Minutes are required' : ''}
          />
        </Box>
      </>
    );
  }

  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '24px',
      }}
    >
      <Box>
        <Typography variant="subtitle3">Duration</Typography>
        <Typography id="current_duration" variant="body1">
          {durationView}
        </Typography>
      </Box>
      <Button
        id="batch_edit_duration"
        variant="text"
        onClick={() => setDurationEdit(true)}
        disabled={isLoading}
      >
        Edit
      </Button>
    </Box>
  );
}

export default DurationEdit;
