/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { utcToZonedTime, format } from 'date-fns-tz';
import { useSelector, useDispatch } from 'react-redux';
import {
  setBatchJobs,
  setSelectedEditField,
} from 'state/ducks/staffingWorkPage/actions';
import { HoverableDateTimeDiv } from '../style';
import JobDatePicker from './JobDatePicker';
import { determineDisabledText, determineShowDateTimePicker } from '../utils';
import EditPencilOverlay from './EditPencilOverlay';

function JobDateInfo({
  id,
  status,
  startDate,
  storeTimezone,
  createdJobStartAfter,
  createdJobFinishBefore,
  advantageSourceSystemName,
  externalIdentifier,
  getUpdatedJob,
  job,
}) {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const isCompletedDateTime = status === 'Completed';
  const isCanceled = status === 'Canceled';
  const isNotEditable = advantageSourceSystemName !== 'JET';
  const isDisabled = isCanceled || isNotEditable || isCompletedDateTime;
  const [isHovering, setIsHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const startDateWithTimezone = utcToZonedTime(startDate, storeTimezone);
  const day = format(startDateWithTimezone, 'EEEE', {
    timeZone: storeTimezone,
  });
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const isSingleMode = Array.from(centralMode.values())[0] === 'single';

  const flagName = 'showSingleModeInlineEdit';
  const featureFlags = useSelector((state) => state.featureFlags);
  const isSingleEditModeEnabled = featureFlags[flagName];

  const isEditDrawerEnabled =
    isSingleEditModeEnabled &&
    isSingleMode &&
    !isDisabled &&
    job.advantage_source_system_name === 'JET';

  const showDatePicker = determineShowDateTimePicker(
    isHovering,
    isActive,
    isLoading,
    !isDisabled,
    isSingleMode
  );

  const handleOpenBatchEdit = () => {
    if (isEditDrawerEnabled) {
      dispatch(setSelectedEditField('date'));
      dispatch(setBatchJobs([job]));
    }
  };

  return (
    <HoverableDateTimeDiv
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onBlur={() => {
        if (!isPickerOpen) {
          setIsActive(false);
        }
      }}
      onClick={isEditDrawerEnabled ? handleOpenBatchEdit : undefined}
      className="job-date-wrapper"
      css={{
        height: '100%',
      }}
    >
      {showDatePicker ? (
        isEditDrawerEnabled ? (
          <EditPencilOverlay id="date-edit" />
        ) : (
          <JobDatePicker
            id={id}
            value={startDateWithTimezone}
            minDateTime={utcToZonedTime(createdJobStartAfter, storeTimezone)}
            maxDateTime={utcToZonedTime(createdJobFinishBefore, storeTimezone)}
            externalIdentifier={externalIdentifier}
            advantageSourceSystemName={advantageSourceSystemName}
            getUpdatedJob={getUpdatedJob}
            storeTimezone={storeTimezone}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setIsActive={setIsActive}
            isActive={isActive}
            setIsHovering={setIsHovering}
            setIsPickerOpen={setIsPickerOpen}
            isPickerOpen={isPickerOpen}
          />
        )
      ) : (
        <Tooltip
          title={determineDisabledText({
            isNotEditable,
            isCompletedDateTime,
            isCanceled,
          })}
        >
          <Box>
            <section>
              <Typography
                variant="body2"
                css={
                  isSingleEditModeEnabled && {
                    fontWeight: '600',
                    color: palette.secondary.main,
                  }
                }
              >
                {format(startDateWithTimezone, 'M/d/yyyy', {
                  timeZone: storeTimezone,
                })}
              </Typography>
            </section>
            <Typography
              variant="body2"
              css={isSingleEditModeEnabled && { color: palette.secondary.main }}
            >
              {day}
            </Typography>
          </Box>
        </Tooltip>
      )}
    </HoverableDateTimeDiv>
  );
}

export default JobDateInfo;
