/* eslint-disable no-nested-ternary */
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { endOfDay, isValid, isWithinInterval, startOfDay } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { NO_ASSOCIATE } from '../constants';

const getJobIds = (jobs) => jobs.map(({ id }) => id);
const getUserIds = (jobs) => jobs.map((job) => job.user?.id || null);

export const convertToMinutes = (hours, minutes) =>
  Number(hours) * 60 + Number(minutes);

export const convertToHoursAndMinutes = (minutes) => ({
  hours: String(Math.floor(Number(minutes) / 60)),
  minutes: String(Number(minutes) % 60),
});

export const determineAssociateToDisplay = (jobs, isSelectAllPages) => {
  if (isSelectAllPages) {
    return {
      name: '(Multiple Values)',
      ids: [],
      jobIds: [],
    };
  }
  const noJobs = jobs.length === 0;
  const associateNames = jobs.map((job) => job.user?.full_name || '');
  const uniqueAssociateNames = [...new Set(associateNames)];
  return noJobs
    ? NO_ASSOCIATE
    : {
        name:
          uniqueAssociateNames.length === 1
            ? uniqueAssociateNames[0]
            : '(Multiple Values)',
        ids: getUserIds(jobs),
        jobIds: getJobIds(jobs),
      };
};

const multipleValues = {
  view: '(Multiple Values)',
  edit: null,
};

export const determineDateToDisplay = (jobDates, isSelectAllPages) => {
  const dates = jobDates.map((date) => format(date, 'MM/dd/yyyy'));
  const uniqueDates = [...new Set(dates)];
  return isSelectAllPages || uniqueDates.length > 1
    ? multipleValues
    : { view: uniqueDates[0], edit: jobDates[0] };
};

export const determineTimeToDisplay = (jobTimes, isSelectAllPages) => {
  const times = jobTimes.map((time) => format(time, 'hh:mm a'));
  const uniqueTimes = [...new Set(times)];
  return isSelectAllPages || uniqueTimes.length > 1
    ? multipleValues
    : { view: uniqueTimes[0], edit: jobTimes[0] };
};

export const determineDurationView = (hours, minutes) => {
  const hoursView = hours === '0' ? '' : `${hours}h`;
  const minutesView = minutes === '0' ? '' : `${minutes}m`;
  return [hoursView, minutesView].filter((view) => view).join(' ');
};

export const determineDurationToDisplay = (jobDurations, isSelectAllPages) => {
  const uniqueDurations = [...new Set(jobDurations)];
  if (isSelectAllPages || uniqueDurations.length > 1) {
    return {
      ...multipleValues,
      edit: {
        hours: '',
        minutes: '',
      },
    };
  }
  const { hours, minutes } = convertToHoursAndMinutes(uniqueDurations[0]);
  return {
    view: determineDurationView(hours, minutes),
    edit: {
      hours,
      minutes,
    },
  };
};

export const determineAssignmentsSelectedLabel = (batchJobsCount) =>
  `Assignment${batchJobsCount > 1 ? 's' : ''} selected`;

export const isOtherReasonErrored = (otherReason) =>
  otherReason.length < 4 || otherReason.length > 40;

export const isReassignmentErrored = (
  containsAutoStaffedJob,
  selectedReassignmentReason,
  otherReason
) =>
  containsAutoStaffedJob &&
  (Object.keys(selectedReassignmentReason).length === 0 ||
    (selectedReassignmentReason.label === 'Other' &&
      isOtherReasonErrored(otherReason)));

export const isSaveDisabled = ({ associate, startDate, startTime, duration }) =>
  associate.old.name === associate.new.name &&
  startDate.old === startDate.new &&
  startTime.old === startTime.new &&
  duration.old === duration.new;

export const isSaveButtonDisabled = (
  { associate, startDate, startTime, duration },
  isLoading,
  isSelectAllPagesLoading,
  isErrored,
  errorDate,
  errorTime
) => {
  const unchangedFields =
    associate.old.name === associate.new.name &&
    String(startDate.old) === String(startDate.new) &&
    startTime.old === startTime.new &&
    duration.old === duration.new;

  return (
    unchangedFields ||
    isLoading ||
    isSelectAllPagesLoading ||
    isErrored ||
    errorDate ||
    errorTime
  );
};

export const determineMutation = (associate, isBatchModeOn) => {
  if (isBatchModeOn) {
    if (!isEmpty(associate.old.name) && isEmpty(associate.new.name)) {
      return 'unassign';
    }
    if (associate.old.name !== associate.new.name) {
      return 'assign';
    }
    return 'update';
  }
  return 'auto';
};

export const determineSingleMutation = (associate) => {
  if (!isEmpty(associate.old.name) && isEmpty(associate.new.name)) {
    return 'concierge-unclaim';
  }
  if (associate.old.name !== associate.new.name) {
    return 'concierge-claim';
  }
  return 'update-schedule';
};

export const editToastMessage = (url, assignmentId, associateName) => {
  const successMessages = {
    'concierge-unclaim': `Associate was successfully removed from Assignment ID #${assignmentId}.`,
    'concierge-claim': `Associate for Assignment ID #${assignmentId} successfully updated to ${associateName}.`,
    'update-schedule': `Assignment ID #${assignmentId} has been successfully updated.`,
  };

  return successMessages[url];
};

export const determineRequest = (
  url,
  { associate, startDate, startTime, duration, reassignmentReason },
  jobIds,
  batchId,
  batchName
) => ({
  url,
  payload: {
    ...(associate.old.name === associate.new.name
      ? {}
      : { user_id: associate.new.ids[0] }),
    ...(batchId ? { job_list_id: batchId } : {}),
    ...(batchName ? { batch_name: batchName } : {}),
    ...(duration.old === duration.new ? {} : { duration: duration.new }),
    ...(jobIds.length > 0 ? { job_ids: jobIds } : {}),
    ...(startDate.old === startDate.new ? {} : { start_date: startDate.new }),
    ...(startTime.old === startTime.new ? {} : { start_time: startTime.new }),
    ...(reassignmentReason.reasonId
      ? { change_reason_id: reassignmentReason.reasonId }
      : {}),
    ...(reassignmentReason.otherReason
      ? { other_reason: reassignmentReason.otherReason }
      : {}),
  },
});

function getJobStartAfter(startDate, timezone) {
  const { old: oldDate, new: newDate } = startDate;

  if (oldDate === newDate) {
    return undefined;
  }

  const date = oldDate !== newDate ? newDate : oldDate;

  const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedTime = utcToZonedTime(new Date(date), systemTimezone);

  return zonedTimeToUtc(zonedTime, timezone).toISOString();
}

export const determineSingleRequest = (
  url,
  { associate, startDate, duration, reassignmentReason },
  timezone
) => {
  const jobStartAfter = getJobStartAfter(startDate, timezone);

  return {
    url,
    payload: {
      ...(associate.old.name === associate.new.name
        ? {}
        : { user_id: associate.new.ids[0] }),
      ...(duration.old === duration.new
        ? {}
        : { estimated_time: duration.new }),
      ...(jobStartAfter ? { job_start_after: jobStartAfter } : {}),
      ...(reassignmentReason.reasonId
        ? { change_reason_id: reassignmentReason.reasonId }
        : {}),
      ...(reassignmentReason.otherReason
        ? { other_reason: reassignmentReason.otherReason }
        : {}),
    },
  };
};

export const determineBatchEditHeader = {
  single: 'Edit',
  batch: 'Edit',
  auto: 'AI Assisted Scheduling',
};

export const determineSaveButtonText = {
  single: 'Save',
  batch: 'Save',
  auto: 'Submit',
};

export const determineBatchEditLabel = {
  single: 'Only edit fields that need to be changed.',
  batch: 'Only edit fields that need to be changed.',
  auto: 'Automatically assign associate to selected unstaffed assignment.',
};

export const determineIfDateIsWithinRange = (
  date,
  startDate,
  endDate,
  isOpen,
  isSingleEditModeEnabled
) => {
  if (isOpen && isSingleEditModeEnabled) {
    const start = startOfDay(startDate);
    const end = endOfDay(endDate);

    if (start && end && start <= end) {
      return isWithinInterval(date, { start, end });
    }
  }
  return false;
};

export const determineIfTimeIsWithinRange = (
  targetDate,
  startDate,
  endDate,
  isOpen,
  isSingleEditModeEnabled
) => {
  if (
    isOpen &&
    isSingleEditModeEnabled &&
    isValid(startDate) &&
    isValid(endDate) &&
    isValid(targetDate) &&
    startDate <= endDate
  ) {
    return isWithinInterval(targetDate, { start: startDate, end: endDate });
  }
  return false;
};
