import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { Box, Button, Typography } from '@mui/material';
import { utcToZonedTime } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { isValid } from 'date-fns';
import { determineIfTimeIsWithinRange } from '../utils';

function DateEdit({
  dateEdit,
  isLoading,
  selectedDate,
  setSelectedDate,
  errorDate,
  setTimeEdit,
  setErrorDate,
  setDateEdit,
  isOpen,
}) {
  const batchJob = useSelector(
    (state) => state.staffingWorkPage.batch.batchJobs
  )[0];
  const flagName = 'showSingleModeInlineEdit';
  const featureFlags = useSelector((state) => state.featureFlags);
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const currentMode = Array.from(centralMode.values())[0];
  const isSingleModeOn = currentMode === 'single';
  const isSingleEditModeEnabled = featureFlags[flagName] && isSingleModeOn;

  const handleDateChange = (event) => {
    const isTimeWithinRange = determineIfTimeIsWithinRange(
      event,
      utcToZonedTime(
        batchJob?.created_job_start_after,
        batchJob?.store_location_timezone
      ),
      utcToZonedTime(
        batchJob?.created_job_finish_before,
        batchJob?.store_location_timezone
      ),
      isOpen,
      isSingleEditModeEnabled
    );

    setSelectedDate({
      ...selectedDate,
      edit: isValid(event) ? event : null,
    });

    if (!isTimeWithinRange) {
      setTimeEdit(true);
    }
  };

  if (dateEdit) {
    return (
      <DesktopDatePicker
        autoFocus
        label="Date"
        value={selectedDate.edit}
        disabled={isLoading}
        minDate={
          isSingleEditModeEnabled &&
          batchJob &&
          utcToZonedTime(
            batchJob.created_job_start_after,
            batchJob.store_location_timezone
          )
        }
        maxDate={
          isSingleEditModeEnabled &&
          batchJob &&
          utcToZonedTime(
            batchJob.created_job_finish_before,
            batchJob.store_location_timezone
          )
        }
        onChange={handleDateChange}
        onError={(value) => {
          if (isSingleEditModeEnabled) {
            setTimeEdit(true);
            setErrorDate(value);
          }
        }}
        css={{ width: '100%', marginTop: '24px' }}
        slotProps={{
          textField: {
            id: 'batch_edit_date_picker',
            helperText: errorDate
              ? 'Date is outside available range'
              : undefined,
          },
        }}
      />
    );
  }

  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '24px',
      }}
    >
      <Box>
        <Typography variant="subtitle3">Date</Typography>
        <Typography id="current_date" variant="body1">
          {selectedDate.view}
        </Typography>
      </Box>
      <Button
        id="batch_edit_date"
        variant="text"
        onClick={() => setDateEdit(true)}
        disabled={isLoading}
      >
        Edit
      </Button>
    </Box>
  );
}

export default DateEdit;
