import React, { useState } from 'react';

import Chip from '@mui/material/Chip';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { AssignOrViewAssociateWrapper, AssociateWrapper } from '../style';
import { determineChipColorAndLabel } from '../utils';
import { determineMatchedAssociateAssignButtonText } from '../../../lib/utils';

function Associate({
  associate,
  storeLocationId,
  onShowAssociateDetails,
  handleAssignAssociateClick,
}) {
  const [display, setDisplay] = useState('none');
  const isHomeStore = storeLocationId === associate.home_store_location_id;
  const batchJobs = useSelector(
    (state) => state.staffingWorkPage.batch.batchJobs
  );
  const theme = useTheme();

  return (
    <AssociateWrapper
      className="matched-associate"
      onMouseEnter={() => {
        setDisplay('flex');
      }}
      onMouseLeave={() => {
        setDisplay('none');
      }}
    >
      <Box
        css={{
          padding: '16px',
        }}
      >
        <Stack
          direction="row"
          css={{
            flexWrap: 'wrap',
            alignItems: 'center',
            marginBottom: '4px',
            marginTop: '4px',
          }}
        >
          {associate.exceptions.length === 0 ? (
            <Chip
              id="match-chip"
              css={{ marginRight: '4px' }}
              label="Match"
              size="small"
              color="success"
            />
          ) : (
            associate.exceptions.map((exception) => {
              const { color, label } = determineChipColorAndLabel(exception);
              return (
                <Chip
                  id={`${label}-chip`}
                  color={color}
                  key={exception}
                  label={label}
                  size="small"
                  css={{
                    marginRight: '4px',
                    marginTop: '4px',
                    marginBottom: '4px',
                  }}
                />
              );
            })
          )}
          {isHomeStore && (
            <Chip
              id="home-store-chip"
              label="Home Store"
              color="secondary"
              size="small"
              css={{
                marginRight: '4px',
                marginTop: '4px',
                marginBottom: '4px',
              }}
            />
          )}
        </Stack>

        <Typography variant="subtitle3">{associate.full_name}</Typography>

        <Typography
          data-testid="associate_id"
          variant="body2"
          css={{
            color: theme.palette.text.secondary,
            lineHeight: '110%',
          }}
        >
          {associate.external_id} - {associate.title}
        </Typography>
        <Typography variant="body2" data-testid="associate_division">
          {associate.division_name}
        </Typography>
        <Box
          css={{
            display: 'flex',
            width: '100%',
            marginTop: '8px',
          }}
        >
          <Box css={{ width: '95px' }} data-testid="associate_miles_from_home">
            <Typography
              variant="body2"
              css={{
                fontWeight: '600',
                letterSpacing: '0.15px',
                lineHeight: '100%',
                marginBottom: '4px',
              }}
            >
              {associate.miles_from_job} miles
            </Typography>
            <Typography
              css={{
                fontSize: '12px',
                color: theme.palette.text.secondary,
                letterSpacing: '0.4px',
                lineHeight: '100%',
              }}
            >
              FROM HOME
            </Typography>
          </Box>
          <Divider css={{ borderWidth: '1px', margin: '0 8px' }} />
          <Box css={{ width: '85px' }} data-testid="associate_remaining_hours">
            <Typography
              variant="body2"
              css={{
                fontWeight: '600',
                letterSpacing: '0.15px',
                lineHeight: '100%',
                marginBottom: '4px',
              }}
            >
              {associate.remaining_hours} hours
            </Typography>
            <Typography
              css={{
                fontSize: '12px',
                color: theme.palette.text.secondary,
                letterSpacing: '0.4px',
                lineHeight: '100%',
              }}
            >
              REMAINING
            </Typography>
          </Box>
        </Box>
      </Box>
      <AssignOrViewAssociateWrapper display={display}>
        <Button
          variant="contained"
          className="matched-associate-view-button"
          onClick={() => {
            onShowAssociateDetails({
              hideMatchDetails: false,
              hideAssignButton: false,
              ...associate,
            });
          }}
        >
          View
        </Button>
        <Button
          variant="contained"
          className="matched-associate-assign-button"
          onClick={() =>
            handleAssignAssociateClick(associate.user_id, associate.full_name)
          }
          css={{
            marginLeft: '16px',
          }}
        >
          {determineMatchedAssociateAssignButtonText(batchJobs)}
        </Button>
      </AssignOrViewAssociateWrapper>
      <Divider />
    </AssociateWrapper>
  );
}

export default Associate;
