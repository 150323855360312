import HTTP from '../../core/http';
import { formatFilterCollectionToQueryParams } from '../../lib/utils/queryParamUtils';

export function sendBatchRequest(method, request) {
  return new Promise((resolve, reject) => {
    HTTP[method](request.url, request.payload)
      .then((response) => {
        resolve(response.data.name);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

export function getSelectAllPagesCount(selectedFilterItems) {
  return new Promise((resolve, reject) => {
    const params = formatFilterCollectionToQueryParams(selectedFilterItems);
    HTTP.post('job_list', null, { params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

export function getReassignmentReasons() {
  return new Promise((resolve, reject) => {
    HTTP.get('auto_staff_change_reasons')
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
